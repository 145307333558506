/* Global styles */
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&family=Poppins:wght@400;700&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  background-color: #0d0f1a;
  color: #fff;
  overflow-y: hidden;
  overflow-x: hidden;
}

.pumpfun-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  overflow-y: auto;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  backdrop-filter: blur(15px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.logo {
  height: 60px;
  width: auto;
}

.navbar-links {
  display: flex;
  gap: 1.5rem;
}

.nav-link {
  color: #ffffff;
  text-decoration: none;
  font-size: 1rem;
  position: relative;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #00e5ff;
  text-shadow: 0 0 8px rgba(0, 229, 255, 0.8);
}

.ticker-wrapper {
  background-color: #0e1324;
  color: #ffffff;
  margin-bottom: 1rem;
  width: 100%;
  padding: 1rem 0;
  position: relative;
  font-size: small;
  overflow: hidden;
  box-sizing: border-box;
  height: 3rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.ticker-content {
  display: inline-block;
  white-space: nowrap;
  position: absolute;
  animation: ticker-scroll 30s linear infinite;
  top: 50%;
  transform: translateY(-50%);
}

@keyframes ticker-scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.content {
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 4rem;
}

.input-form input {
  padding: 0.75rem;
  border-radius: 10px;
  border: none;
  width: 400px;
  font-size: 1rem;
  background-color: #292e3e;
  color: #fff;
  text-align: center;
}

.input-form input::placeholder {
  color: #e6d9f3;
}

.input-form {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
}

.submit-button {
  background: linear-gradient(145deg, #141827, #2a3145);
  color: #fff;
  border: none;
  font-size: 1rem;
  border-radius: 12px;
  cursor: pointer;
  box-shadow: 0 4px 15px rgba(0, 229, 255, 0.4);
  height: 48px;
  width: 250px;
  text-align: center;
}

.console-messages {
  background-color: #141827;
  border: 2px solid #2a3145;
  padding: 1.5rem;
  border-radius: 10px;
  width: 80%;
  margin: 1.5rem auto;
  text-align: left;
  font-size: 0.875rem;
  color: #00ff7e;
  max-height: 250px;
  overflow-y: auto;
}

.video-section video {
  width: 100%;
  height: auto;
  max-width: 1200px;
  border-radius: 10px;
}
