.progress-container {
  width: 90%;
  max-width: 1280px;
  position: relative;
  height: 25px;
  background-color: #141827;
  border-radius: 15px;
  box-shadow: 0 0 15px rgba(0, 255, 0, 0.4);
  overflow: hidden;
  margin: 10px auto;
}

.progress-bar {
  height: 100%;
  width: 0%;
  background-color: #04f1f4;
  border-radius: 22px;
  box-shadow: 0 0 15px rgba(0, 255, 0, 0.8);
  transition: width 2s ease-in-out;
}

.loading-indicator {
  position: absolute;
  top: 0;
  left: -80%;
  height: 100%;
  width: 15%;
  background-color: rgba(0, 255, 0, 0.2);
  animation: loading 2.5s infinite linear;
}

@keyframes loading {
  0% {
    left: -100%;
    width: 30%;
  }
  100% {
    left: 100%;
    width: 30%;
  }
}

.steps {
  position: absolute;
  top: 50%;
  width: 99%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  font-size: 11px;
  color: #FFFF00; /* Text color */
  font-weight: bold; /* Makes the text bold */
  padding-left: 5px;  /* Adds space from the left side */
  left: 1px;  /* Adds space from the left side */
  right: 10px; /* Adds space from the right side */
}

.step.active span {
  color: #049006;
}

.progress-line {
  position: absolute;
  top: 50%;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: #00ff00;
  z-index: -1;
}

/* Media Query for Mobile Responsiveness */
@media (max-width: 768px) {
  .progress-container {
    width: 90%;  /* Adjust width to 90% for smaller screens */
    margin: 20px auto;  /* Reduce margin on smaller screens */
  }

  .steps {
    font-size: 8px;  /* Reduce font size for smaller screens */
    padding-left: 2px;  /* Reduce padding for better fit */
  }
  
  .progress-bar {
    transition: width 1.5s ease-in-out; /* Adjust transition speed for smaller screens */
  }
}
