/* Ensure the page takes the full height */
.pumpfun-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0;
  margin: 0;
}
.navbar {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: space-between; /* Space out the logo and button areas */
  padding: 10px 20px;
  position: fixed; /* Keep the navbar at the top */
  width: 100%; /* Ensure the navbar spans the full width */
  top: 0;
  background-color: #333; /* Navbar background color */
  color: white;
  z-index: 1000; /* Ensure the navbar stays on top */
  box-sizing: border-box;
}

.logo {
  position: relative;
  margin-right: auto; /* Pushes other content away */
}

.logo-img {
  width: 100px; /* Adjust the size of the logo */
  height: auto;
}

.navbar-center {
  position: absolute; /* Take the button out of normal flow */
  left: 50%; /* Position relative to the center of the navbar */
  top: 50%; /* Vertically center */
  transform: translate(-50%, -50%); /* Adjust for exact centering */
}

.how-it-works-button {
  background-color: #1e1e1e; /* Match console background */
  color: #00FFFF; /* Matrix-style text color */
  border: 2px solid #00FFFF; /* Glowing border */
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-family: 'Courier New', Courier, monospace;
  text-shadow: 0 0 5px rgba(0, 255, 255, 0.8), 0 0 10px rgba(0, 255, 255, 0.5); /* Glowing effect */
}

.how-it-works-button:hover {
  background-color: #444; /* Slightly lighter hover */
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.7); /* Glow on hover */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Darker background for console feel */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content ul li {
  margin: 10px 0;
}
.modal-content {
  background-color: #1e1e1e; /* Match console background */
  color: #00FFFF; /* Console text color */
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  text-align: left;
  box-shadow: 0 4px 10px rgba(0, 255, 255, 0.3);
  font-family: 'Courier New', Courier, monospace; /* Match console font */
  text-shadow: 0 0 5px rgba(0, 255, 255, 0.8), 0 0 10px rgba(0, 255, 255, 0.5); /* Glowing text effect */
}
.modal-content ul li:before {
  content: "⦿"; /* Custom bullet icon */
  color: #0074d9; /* Blue for emphasis */
  margin-right: 10px;
}
.modal-content h2 {
  color: #FF851B; /* Distinct orange title color */
  font-size: 24px;
  margin-bottom: 10px;
  text-shadow: 0 0 5px rgba(255, 133, 27, 0.8), 0 0 10px rgba(255, 133, 27, 0.5); /* Orange glow */
}
.modal-content ul {
  margin-left: 20px;
  list-style-type: none; /* Remove default bullets */
}


.close-button {
  margin-top: 20px;
  background-color: #0074d9; /* Blue button for contrast */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-family: 'Courier New', Courier, monospace;
}

.close-button:hover {
  background-color: #0056b3; /* Slightly darker blue on hover */
}


.navbar-center {
  flex-grow: 1; /* Take up available space */
  display: flex;
  justify-content: center; /* Center the button horizontally */
}
.how-it-works-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.how-it-works-button:hover {
  background-color: #45a049;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dim background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}


.modal-content {
  background-color: white; /* Light background for readability */
  color: black; /* Black text color */
  padding: 20px;
  border-radius: 10px;
  max-width: 800px; /* Increased width */
  max-height: 400px; /* Reduced height */
  text-align: left; /* Align text to the left */
  overflow-y: auto; /* Add vertical scrolling if content exceeds height */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  font-family: 'Courier New', Courier, monospace; /* Keep consistent font */
}

.modal-content h2 {
  color: #333; /* Dark gray for a softer title */
  font-size: 24px;
  margin-bottom: 10px;
}

.close-button {
  margin-top: 20px;
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #d32f2f;
}


.navbar-links {
  display: flex;
  gap: 20px; /* Space between links */
  align-items: center;
  margin-right: 20px; /* Spacing from the right edge */
}

.how-it-works-btn {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.how-it-works-btn:hover {
  background-color: #45a049;
}

.smart-refresh-btn {
  background-color: #00FF00;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 20px;
}

.smart-refresh-btn:hover {
  background-color: #0056b3;
}

/* Prevent horizontal scrollbar and ensure smooth vertical scrolling */
html, body {
  height: 100%;
  margin: 0; /* Removes default margin */
  overflow-x: hidden; /* Hide horizontal scrollbar completely */
  overflow-y: auto; /* Allow vertical scrolling */
}

/* Quote Wrapper */
.quote-wrapper {
  text-align: center;
  margin: 15px 0; /* Reduced margin */
  font-family: 'Courier New', Courier, monospace; /* Matrix-style font */
}

/* Quote Line */
.quote-line {
  height: 0.75px; /* Reduced line height */
  background-color: #00FF00; /* Neon green color for the lines */
  margin: 7.5px 0; /* Reduced margin */
}

/* Quote Content */
.quote-content {
 font-family: 'Courier New', Courier, monospace; /* Matrix-style font */
  font-size: 13.5px; /* Reduced font size */
  font-weight: bold;
  color: #00FFFF; /* Matrix blue color for the text */
  text-shadow: 0 0 3.75px rgba(0, 255, 255, 0.7), 0 0 7.5px rgba(0, 255, 255, 0.5); /* Glowing effect */
  margin: 7.5px 0; /* Reduced margin */
  letter-spacing: 0.75px; /* Reduced letter-spacing */
  line-height: 1.125; /* Adjusted line-height */
}

/* Navbar Styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px; /* Reduced padding */
  background-color: #333;
  color: white;
  width: 100%; /* Ensures navbar spans the full width */
  box-sizing: border-box;
  position: fixed; /* Fixed position so navbar stays on top */
  top: 0;
  left: 0;
  z-index: 1000; /* Ensures navbar stays on top of all content */
  box-shadow: 0 1.5px 7.5px rgba(0, 0, 0, 0.2); /* Reduced shadow */
}

/* Logo Styling */
.logo {
  position: absolute;
  top: 15px; /* Reduced top margin */
  left: 15px; /* Reduced left margin */
}

.logo-img {
  width: 190px; /* Reduced size of the logo */
  height: auto;
}

/* Navbar Links */
.navbar-links {
  display: flex;
  justify-content: flex-end; /* Align links to the right */
  width: 100%;
}

.navbar-links .nav-link {
  margin: 0 11.25px; /* Reduced margin */
  color: white;
  text-decoration: none;
  padding: 6px 0; /* Reduced padding */
}

.navbar-links .nav-link:hover {
  text-decoration: underline;
}

/* Content Section */
.content {
  flex: 1;
  padding: 90px 15px 15px 15px; /* Reduced padding-top */
  text-align: center;
  box-sizing: border-box;
}

/* Title - AI Powered Rug Checker */
h1 {
  font-size: 18px; /* Reduced font size */
  margin-top: 15px; /* Reduced space */
  margin-bottom: 11.25px; /* Reduced space */
  color: #a0c4ff; /* White bluish color */
}

/* Scrolling Text */
.ticker-wrapper {
  margin-top: 15px; /* Reduced top margin */
  background-color: #f0f0f0;
  padding: 7.5px; /* Reduced padding */
 font-family: 'Courier New', Courier, monospace; /* Matrix-style font */
  font-size: 10.5px; /* Reduced font size */
  color: #333;
  white-space: nowrap;
  overflow: hidden;
}

.ticker-content {
  animation: ticker 7.5s linear infinite; /* Reduced animation speed */
}

@keyframes ticker {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}



/* Input Form Styles */
.input-form {
  margin-top: 22.5px; /* Reduced margin */
}

.input-form input {
  padding: 7.5px; /* Reduced padding */
  font-size: 12px; /* Reduced font size */
  margin-right: 7.5px; /* Reduced margin */
  border-radius: 5px;
}

.input-form button {
  padding: 7.5px 15px; /* Reduced padding */
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.input-form button:hover {
  background-color: #444;
}
#live-logs {
  height: auto; /* Keep the height dynamic */
  max-height: 150px; /* Ensure max height for consistent size */
  width: 80%; /* Adjust width as needed */
  position: absolute; /* Center positioning */
  top: 50%; /* Center vertically */
  left: 42%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Fine-tune centering */
  background-color: transparent; /* No background color */
  color: #2ecc40; /* Text color */

  border-radius: 10px; /* Optional: Rounded corners */
  border: none; /* No borders */
  margin: 0; /* No margin */
  overflow: hidden; /* Remove any scrollbars */
  white-space: normal; /* Allow text to wrap */
}


.console-messages {
  margin-top: 5.5px;
  font-family: 'Courier New', Courier, monospace;
  background-color: #1e1e1e;
  padding: 2px;
  border-radius: 5px;
  width: 60%;
  max-width: 750px;
  margin: 0 auto;
  box-sizing: border-box;
  height: 150px;
  overflow: hidden; /* Completely hide both vertical and horizontal scrollbars */
  position: relative;
  scrollbar-width: none; /* For Firefox: remove the scrollbar */
}

.console-messages::-webkit-scrollbar {
  display: none; /* For Webkit-based browsers: hide the scrollbar */
}


.console-messages ul:empty {
  height: auto; /* Prevent unnecessary height */
  overflow: hidden; /* Completely disable scrollbars */
}
.console-messages ul {
  list-style-type: none; /* Remove bullet points */
  margin: 0; /* Remove extra margin */
  padding: 0; /* Remove extra padding */
  height: auto; /* Let the ul adjust based on content height */
  width: 100%; /* Ensure it fits within the parent container */
  overflow: visible; /* Remove scrolling behavior from ul */
  white-space: normal; /* Wrap long text to avoid overflow */
}



.console-messages ul li {
  word-wrap: break-word; /* Force long words to break into the next line */
  overflow-wrap: break-word; /* Break unbreakable strings (e.g., long URLs) */
}



/* Coin Info Table */
.coin-info-table {
  margin: 15px auto; /* Reduced margin */
  width: 60%; /* Reduced width */
  border-collapse: collapse;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Keeps table content aligned vertically */
}

.coin-info-table th,
.coin-info-table td {
  border: 1px solid #ccc;
  padding: 7.5px; /* Reduced padding */
  text-align: center;
}

.coin-info-table th {
  background-color: #333;
  color: white;
}

.coin-info-table img {
  border-radius: 50%;
}

/* Main Console (e.g., rapid transactions and updates) */
.console-messages.main-console {
  background-color: #f9f9f9;
  color: #0074d9; /* Blue for Main Console text */
}

/* Recommended Strategy Console */
.console-messages.strategy-console {
  background-color: #f9f9f9;
  color: #ff851b; /* Orange for Strategy Console text */
}

/* AI Response Console */
.console-messages.ai-response-console {
  background-color: #f9f9f9;
  color: #2ecc40; /* Green for AI Response Console text */
}

/* Style the scrollbar to match the background */
::-webkit-scrollbar {
  width: 6px; /* Reduced scrollbar width */
  height: 6px; /* Reduced horizontal scrollbar width */
}/* Risk grade text styling */
.risk-grade {
  font-size: 24px; /* Make the font size bigger */
  font-weight: bold;
  text-align: center;
  padding: 10px;
  margin-top: 20px;
  border-radius: 8px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  animation: blink 1s linear infinite; /* Smoother blinking animation */
  color: white; /* Default text color, will be overridden for each risk level */
  display: inline-block; /* To prevent block-level layout */
}

/* High risk text color */
.risk-grade.high-risk {
  color: red; /* Red for high risk */
}

/* Medium risk text color */
.risk-grade.medium-risk {
  color: yellow; /* Yellow for medium risk */
}

/* Low risk text color */
.risk-grade.low-risk {
  color: green; /* Green for low risk */
}

/* Blinking effect */
@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}


::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Match the page background color */
}

::-webkit-scrollbar-thumb {
  background-color: #f0f0f0; /* Match the page background color */
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #ddd; /* Slightly darker when hovered */
}
